import { template as template_4c6793f1ae18462b9196e7424f200c83 } from "@ember/template-compiler";
import Component from '@glimmer/component';
export interface GravatarSignature {
    Element: HTMLImageElement;
    Args: {
        hash: string;
        retina: boolean;
        size: number;
    };
}
export default class Gravatar extends Component<GravatarSignature> {
    get src() {
        const { s } = this;
        const { hash } = this.args;
        return `https://www.gravatar.com/avatar/${hash}?s=${s}`;
    }
    get s() {
        const { retina, size = 250 } = this.args;
        return retina ? size * 2 : size;
    }
    static{
        template_4c6793f1ae18462b9196e7424f200c83(`
    <img
      src={{this.src}}
      class="gravatar"
      width={{@size}}
      height={{@size}}
      alt="avatar"
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
