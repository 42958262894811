import { template as template_f3da1d4f76ff4173b3661777dd8f4a2a } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
import type { ApplicationRouteModel } from '../routes/application';
interface IndexRouteSignature {
    Args: {
        model: ApplicationRouteModel;
    };
}
const Template: TOC<IndexRouteSignature> = template_f3da1d4f76ff4173b3661777dd8f4a2a(`
  <main class="cover">
    <h1>{{@model.name}}</h1>
    <p>Development on&nbsp;<a
        href={{@model.project.url}}
        class="link"
        target="_blank"
        rel="noopener noreferrer"
      >{{@model.project.title}}</a>
      at&nbsp;<a
        href={{@model.company.url}}
        class="link"
        target="_blank"
        rel="noopener noreferrer"
      >{{@model.company.title}}</a></p>
  </main>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(Template);
