import { template as template_60ff4294374a432987ab82806faac3d6 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
import type { ApplicationRouteModel } from '../routes/application';
import Header from '../components/header';
import Footer from '../components/footer';
interface ApplicationRouteSignature {
    Args: {
        model: ApplicationRouteModel;
    };
}
const Template: TOC<ApplicationRouteSignature> = template_60ff4294374a432987ab82806faac3d6(`
  <div class="cover-container">
    <Header @gravatar={{@model.gravatar}} @links={{@model.links}} />
    {{outlet}}
    <Footer @city={{@model.city}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(Template);
